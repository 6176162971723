import { createSelector } from 'reselect'



export const hudSitesSelector = (state => state.hudSites)
export const tournamentSitesSelector = (state => state.tournamentSites)

export const hudPlayersSelector = (state => state.hudPlayers)
export const tournPlayersSelector = (state => state.tournamentPlayers)

export const hudPlayersBySiteSelector = (site) => createSelector(
    hudPlayersSelector,
    (data) => {
        return data?.[site]?.players || []
    }
)


export const tournPlayersBySiteSelector = (site) => createSelector(
    tournPlayersSelector,
    (data) => {
        return data?.[site]?.players || []
    }
)


export const allPlayersBySiteSelector = (site) => createSelector(
    hudPlayersSelector,
    tournPlayersSelector,
    (data) => {
        if (data?.[site]?.isLoading) {
            return ['Loading...']
        }
        return data?.[site]?.players || []
    }
)

export const allPlayersSelector = () => createSelector(
    hudPlayersSelector,
    tournPlayersSelector,
    (data) => {
        let allPlayersArray = []
        Object.keys(data).forEach((key) => {
            allPlayersArray = allPlayersArray.concat(data[key]?.players)
        })

        return allPlayersArray
    }
)


export const hudDataSelector = (state => state.singlePlayerHudData)
export const tournDataSelector = (state => state.singlePlayerTournamentStats)
export const aggTournDataSelector = (state => state.aggregateTournamentData)
//export const aggregateTournDataSelector = (state => state?.aggregateTournamentData || {})

export const hudDataBySiteSelector = (site) => createSelector(
    hudDataSelector,
    (data) => {
        return data?.[site] || []
    }
)

export const hudDataByNameSelector = (site, name) => createSelector(
    hudDataSelector,
    (data) => {
        return data?.[site]?.['hud_data'] || []
    }
)

export const tournDataBySiteSelector = (site) => createSelector(
    tournDataSelector,
    (data) => {
        return data?.[site] || []
    }
)

export const tournDataByNameSelector = (site, name) => createSelector(
    tournDataSelector,
    (data) => data?.[site]?.name
)


export const aggregateTournDataBySiteSelector = (site) => createSelector(
    aggTournDataSelector,
    (data) => {
        return data?.[site]
    }
)