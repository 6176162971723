import { Provider } from 'react-redux'
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import createStore from './reducers/store'
import ReactGA from 'react-ga4'
import StandingsTicker from './components/StandingsTicker';
import StandingsTable from './components/StandingsTable';




ReactGA.initialize('G-8P7GTRR6SQ')




const root = ReactDOM.createRoot(document.getElementById('root'));
const store = createStore()


//const location = useLocation()
//const path = location.pathname




if (window.location.pathname.indexOf('/overlay/ticker') !== -1) {
  root.render(
  <React.StrictMode>
    <StandingsTicker searchParams={new URLSearchParams(window.location.search)}/>
  </React.StrictMode>
  )
}
else if(window.location.pathname.indexOf('/overlay/table') !== -1) {
  console.log('here')
  root.render(

    <React.StrictMode>
      <Provider store={store}>
      <StandingsTable searchParams={new URLSearchParams(window.location.search)}/>
      </Provider>
    </React.StrictMode>
    )
}
else {
  root.render(
    <React.StrictMode>
      <Provider store={store}><App searchParams={new URLSearchParams(window.location.search)} /></Provider>
    </React.StrictMode>
  )

}






// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
