import { useTable, useSortBy, usePagination } from "react-table";
import { hudColumns, Styles, tournamentColumns, aggTournamentColumns } from './table-util'
import './table.css'
import loading from '../../assets/loading.gif'



function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 }
    },
    useSortBy,
    usePagination
  );


  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            //console.log("i is " + i)

            const rowMod = i % 2
            //console.log("row mod is " + rowMod)
            prepareRow(row)
            return (
              <tr className={rowMod == 1 ? "odd" : "even"} {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      <div className="pagination" style={{ paddingBottom: 20 }}>
        {rows.length > pageSize &&
          <>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>{' '}
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>{' '}
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>{' '}
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </button>{' '}
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <span>
              | Go to page:{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                style={{ width: '100px' }}
              />
            </span>{' '}
            <select
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 50, 100, 500].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </>
        }
      </div>

    </>
  );
}

export function HUDTable({ dataObj, title }) {

  if (dataObj?.isLoading) {
    return (
      <Styles>
        <div style={{ marginBottom: '10em', width: '50%', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
          <img
            src={loading} alt="loading" style={{ alignSelf: 'center' }}
          />
        </div>
      </Styles>
    )
  }

  if (!dataObj?.data) return null

  return (
    <Styles>
      <Table data={dataObj?.data} columns={[{ ...hudColumns[0], Header: title }]} />
    </Styles>

  )
}

export function TournamentTable({ dataObj, title }) {
  console.log("dataObj is ")
  console.log(dataObj)

  if (dataObj?.isLoading) {
    return (
      <Styles>
        <div style={{ marginBottom: '10em', width: '50%', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
          <img
            src={loading} alt="loading" style={{ alignSelf: 'center' }}
          />
        </div>
      </Styles>
    )
  }

  if (!dataObj?.data) return null

  return (
    <Styles>
      <Table data={dataObj?.data} columns={[{ ...tournamentColumns[0], Header: title }]} />
    </Styles>

  )
}

export function AggregateTournamentTable({ dataObj, title }) {

  if (dataObj?.isLoading) {
    return (
      <Styles>
        <div style={{ marginBottom: '10em', width: '50%', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
          <img
            src={loading} alt="loading" style={{ alignSelf: 'center' }}
          />
        </div>
      </Styles>
    )
  }


  const headerTitle = `${title} (${dataObj?.start} - ${dataObj?.end})`



  return (
    <Styles>
      <Table data={dataObj?.table || []} columns={[{ ...aggTournamentColumns[0], Header: headerTitle }]} />
    </Styles>

  )
}