import { combineReducers } from 'redux';

import {
  getTournamentStatSites,
  getHudStatSites,
  getAllHudPlayers,
  getAllTournamentPlayers,
  getSinglePlayerHudStats,
  getSinglePlayerTournamentStats,
  getAggregateTournamentStats,
  setStat,
  getRegisteringTournaments,
  getRunningTournaments,
  getRandomTournament,
  getTournamentData
} from './reducers'

export default combineReducers({
  tournamentSites: getTournamentStatSites,
  hudSites: getHudStatSites,
  hudPlayers: getAllHudPlayers,
  tournamentPlayers: getAllTournamentPlayers,
  singlePlayerHudData: getSinglePlayerHudStats,
  singlePlayerTournamentStats: getSinglePlayerTournamentStats,
  aggregateTournamentData: getAggregateTournamentStats,
  stats: setStat,
  registeringTournaments: getRegisteringTournaments,
  runningTournaments: getRunningTournaments,
  randomTournament: getRandomTournament,
  tournamentData: getTournamentData

});
