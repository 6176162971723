import React from "react";
import './statpicker.css'
import {
    getTournamentStatSites,
    getHudStatSites,
    getAllHudPlayers,
    getAllTournamentPlayers,
    getSinglePlayerHudStats,
    removeSinglePlayerHudStats,
    getSinglePlayerTournamentStats,
    removeSinglePlayerTournamentStats,
    getAggregateTournamentStats,
    resetSinglePlayerHudStats,
    resetSinglePlayerTournamentStats,
    setStatType,
    setStatSite,
} from "../actions/actions";


import { useDispatch, useSelector, useStore, MapStateToProps, connect } from "react-redux";
import { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Select from 'react-select'


import { aggregateTournDataBySiteSelector, hudPlayersSelector, hudPlayersBySiteSelector, tournPlayersBySiteSelector, hudDataBySiteSelector, tournDataBySiteSelector, tournPlayersSelector } from "../selectors/players";
import { hudSitesSelector, tournamentSitesSelector } from "../selectors/sites";

import { AggregateTournamentTable, HUDTable, TournamentTable } from "./HUDTable/StatTable";
import ReactTags from 'react-tag-autocomplete'


const KeyCodes = {
    comma: 188,
    enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];



function StatPicker(props) {
    const dispatch = useDispatch()
    console.log("check props")
    console.log(props)
    const { selectedSite,
        siteHudPlayers,
        siteTournPlayers,
        aggregateTournData,
        statType } = props

    console.log("selected site is")
    console.log(selectedSite)

    const [statTypeOption, setStatTypeOption] = useState({ value: null, label: "Select Category" })
    const [selectedSiteOption, setSelectedSiteOption] = useState({ value: null, label: 'Select a Site' })
    const [searchTags, setSearchTags] = useState([])
    const [inputIsLocked, setInputIsLocked] = useState(false)
    const [searchInput, setSearchInput] = useState('')
    const [showFullData, setShowFullData] = useState(false)



    useEffect(() => {
        dispatch(getTournamentStatSites())
        dispatch(getHudStatSites())

    }, [])

    // const handleSuggestionsFilter = (suggestion) => {

    //     const foo = searchTags.map(item => item.name).indexOf(suggestion.name) !== -1
    //     console.log(foo)
    //     return foo
    // }


    const createSuggestions = (playerNames) => {
        console.log(playerNames)
        if (playerNames.length === 0) return []
        return playerNames.map((name, id) => ({ id, name }))
    }


    const handleDelete = i => {
        const tagToRemove = searchTags[i]
        const playerToRemove = tagToRemove?.name
        const site = selectedSite
        if (searchTags.length === 1) {
            setInputIsLocked(false)
        }
        if (statType === 'tournamentSites') {
            dispatch(removeSinglePlayerTournamentStats(site, playerToRemove))

        }
        else if (statType === 'hudSites') {
            dispatch(removeSinglePlayerHudStats(site, playerToRemove))
        }

        setSearchTags(searchTags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setSearchTags([...searchTags, tag]);
        if (statType === 'hudSites') {
            dispatch(getSinglePlayerHudStats(selectedSite, tag.name))
        }

        else if (statType === 'tournamentSites') {
            dispatch(getSinglePlayerTournamentStats(selectedSite, tag.name))
        }


        setInputIsLocked(true)
    };

    const handleDrag = (tag, currPos, newPos) => {
        const newTags = searchTags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);

        // re-render
        setSearchTags(newTags);
    };

    const handleTagClick = index => {
        ////console.log('The tag at index ' + index + ' was clicked');
    };

    const renderTable = (data) => {
        return (
            <div>
                {
                    searchTags.length > 0 &&
                    statType === 'hudSites' &&
                    selectedSite &&
                    !showFullData &&
                    <HUDTable dataObj={data} title={selectedSite} />
                }

                {
                    searchTags.length > 0 &&
                    statType === 'tournamentSites' &&
                    selectedSite &&
                    !showFullData &&
                    <TournamentTable dataObj={data} title={selectedSite} />
                }

                {showFullData &&
                    statType === 'tournamentSites' &&
                    selectedSite &&
                    <AggregateTournamentTable dataObj={data} title={selectedSite} />}
            </div>
        )
    }

    const renderPlayerSearch = (searchSuggestions, aggTableData) => {
        return (

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', marginRight: 'auto', marginTop: '-1rem' }}>
                {statType === 'tournamentSites' && selectedSite && !showFullData &&
                    <Button
                        style={{ height: '75%', minHeight: 38, marginLeft: '1em', marginRight: '1em' }}
                        size="lg"
                        variant="Light"
                        onClick={() => {
                            if (selectedSite) {
                                setShowFullData(true)
                                setInputIsLocked(true)
                                if (!aggTableData?.table) {
                                    dispatch(getAggregateTournamentStats(selectedSite))
                                }

                                setShowFullData(true)

                            }



                        }}
                    >
                        See Full Table
                    </Button>
                }
                {
                    selectedSite && !showFullData &&
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginLeft: '1em' }}>
                        <div>
                            <ReactTags

                                tags={searchTags}
                                suggestions={searchSuggestions}
                                noSuggestionsText='No matching players'
                                suggestionsFilter={
                                    ((sug, query) => {
                                        const regexp = new RegExp(`(?:^|\\s)${(query.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&'))}`, 'i')
                                        return regexp.test(sug.name) && searchTags.map(t => t.name).indexOf(sug.name) === -1

                                    })
                                }
                                onDelete={handleDelete}
                                onAddition={handleAddition}
                                allowDeleteFromEmptyInput={false}
                                allowBackspace={false}
                                placeholderText={"Search player"}
                            />
                        </div>


                    </div>

                }

            </div>

        )
    }


    const renderSiteList = (hudSites, tournSites, hudPlayers, tournPlayers) => {

        if (statType === null) return

        let sites = []

        hudSites.forEach((site) => {
            if (!hudPlayers?.[site]) {
                dispatch(getAllHudPlayers(site))
            }
        })

        tournSites.forEach((site) => {
            if (!tournPlayers?.[site]) {
                dispatch(getAllTournamentPlayers(site))
            }
        })

        if (statType === 'tournamentSites') {
            sites = tournSites
        }

        if (statType === 'hudSites') {
            sites = hudSites
        }

        const options = sites.reduce((objArray, site) => {
            objArray.push({ label: site, value: site })
            return objArray
        }, [{ label: "Select a Site", value: null }])

        return (

            <Select
                name="siteSelect"
                options={options}
                value={selectedSiteOption}
                onChange={(newValue) => {
                    setSelectedSiteOption(newValue)
                    dispatch(setStatSite(newValue.value))

                }}
                minWidth={50}
                isDisabled={inputIsLocked}
                styles={{
                    width: '100%',
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? 'black' : "darkgrey",
                        width: '100%',
                        marginLeft: '1em',
                        marginRight: 'auto',
                    }),
                }
                }
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    border: '2px solid',
                    colors: {
                        ...theme.colors,
                        primary25: 'grey',
                        primary: 'black',
                    },
                })} />
        )

    }


    return (
        <div>{!statType && <p>Use the interface below to get query data collection from various online poker sites.</p>}
        <div id={"selects"} style={{ display: "flex", flexDirection: 'row', alignItems: 'flex-end', marginBottom: '2em' }}>
                <Select
                    size="lg"
                    id="dataSelect"
                    value={statTypeOption}
                    aria-label="Select"
                    isDisabled={inputIsLocked}
                    name="dataCategory"
                    options={[{ label: "Select Category", value: null }, { label: "Tournament Data", value: "tournamentSites" }, { label: "Hand / HUD Data", value: "hudSites" }]}
                    onChange={(newStatType) => {
                        setStatTypeOption(newStatType)
                        dispatch(setStatType(newStatType.value))
                        if (newStatType.value === 'hudSites') {
                            setShowFullData(false)
                        }

                    }}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? 'black' : "darkgrey",
                            marginLeft: '1em'
                        }),

                    }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: 'grey',
                            primary: 'black',
                        },
                    })} >

                </Select>

                {renderSiteList(
                    useSelector(hudSitesSelector),
                    useSelector(tournamentSitesSelector),
                    useSelector(hudPlayersSelector),
                    useSelector(tournPlayersSelector)
                )}

                {inputIsLocked &&
                    <div style={{ paddingLeft: '1em' }}>
                        <Button
                            style={{ minHeight: 30, marginLeft: '1em' }}
                            size="lg"
                            variant="Light"
                            onClick={() => {
                                setSearchTags([])
                                dispatch(resetSinglePlayerHudStats())
                                dispatch(resetSinglePlayerTournamentStats())
                                setSelectedSiteOption({ value: null, label: "Select a Site" })

                                setStatTypeOption({ value: null, label: "Select Category" })
                                dispatch(setStatSite(null))
                                setInputIsLocked(false)
                                setShowFullData(false)
                            }}
                        >
                            Reset
                        </Button>
                    </div>}
            </div>

            <div>
                {renderTable(
                    useSelector((state) => {
                        if (statType === 'hudSites' && selectedSite && !showFullData) {
                            return hudDataBySiteSelector(selectedSite)(state)
                        }
                        else if (statType === 'tournamentSites' && selectedSite && !showFullData) {
                            return tournDataBySiteSelector(selectedSite)(state)
                        }
                        else if (statType === 'tournamentSites' && showFullData) {
                            return aggregateTournDataBySiteSelector(selectedSite)(state)
                        }
                    }))}


                <div style={{ marginBottom: '2em', marginTop: -10 }}>
                    {renderPlayerSearch(
                        createSuggestions(useSelector((state) => {
                            if (statType === 'hudSites') {
                                return hudPlayersBySiteSelector(selectedSite)(state)
                            }
                            else if (statType === 'tournamentSites') {
                                return tournPlayersBySiteSelector(selectedSite)(state)
                            }
                            return []
                        },), useSelector(aggregateTournDataBySiteSelector(selectedSite)))
                    )}
                </div>
            </div>
            <div class=" css-b62m3t-container">
            </div>
            
        </div>




    )
}

const mapStateToProps = (state, ownProps) => {
    console.log("check own props")
    console.log(ownProps)
    const { selectedSite } = ownProps
    return {
        selectedSite: state.stats.site,
        statType: state.stats.statType,
        siteHudPlayers: hudPlayersBySiteSelector(state.stats.selectedSite)(state),
        siteTournPlayers: tournPlayersBySiteSelector(state.stats.selectedSite)(state)


    }
}

export default connect(mapStateToProps)(StatPicker);