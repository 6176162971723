import styled from 'styled-components'

export const hudColumns = [
  {
    Header: `Player HUD Stats`,
    columns: [
      {
        Header: "Player",
        accessor: "player_name"
      },
      {
        Header: "# Hands",
        accessor: "total_hands"
      },
      {
        Header: "VPIP %",
        accessor: "VPIP"
      },
      {
        Header: "Preflop Raise %",
        accessor: "PFR"
      },
      {
        Header: "3-Bet %",
        accessor: "3BET"
      },
      {
        Header: "Went To Showdown %",
        accessor: "WTSD"
      },
      {
        Header: "Won at Showdown %",
        accessor: "WSD"
      },
      {
        Header: "Won When Saw Flop %",
        accessor: "WWSF"
      },
      {
        Header: "Aggression",
        accessor: 'AG'
      }
    ]
  }
]

export const tournamentColumns = [
  {
    Header: "Tournament Stats", columns: [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Net Winnings', accessor: 'net_winnings' },
      { Header: 'Avg Winnings', accessor: 'avg_winnings' },
      { Header: '# Entries', accessor: 'entries' },
      { Header: '# Cashed', accessor: 'wins' },
      { Header: 'Win %', accessor: 'win_pct' },
      { Header: 'Last Tourn Date', accessor: 'last_tourn_date' }
    ]
  }
];

export const aggTournamentColumns = [
  {
    Header: "Tournament Stats", columns: [
      { Header: 'Rank', accessor: 'rank' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Net Winnings', accessor: 'net_winnings' },
      { Header: 'Avg Winnings', accessor: 'avg_winnings' },
      { Header: '# Entries', accessor: 'entries' },
      { Header: '# Cashed', accessor: 'wins' },
      { Header: 'Win %', accessor: 'win_pct' },
      { Header: 'Last Tourn Date', accessor: 'last_tourn_date' }
    ]
  }
];

export const hudTournamentHybridColumns = [
  {
    Header: "Tournament Stats", columns: [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Net Winnings', accessor: 'net_winnings' },
      { Header: 'Avg Winnings', accessor: 'avg_winnings' },
      { Header: '# Entries', accessor: 'entries' },
      { Header: '# Cashed', accessor: 'wins' },
      { Header: 'Win %', accessor: 'win_pct' }
    ]
  },
  {
    Header: 'HUD Stats', columns: [
      { Header: '# Hands', accessor: 'total_hands' },
      { Header: 'VPIP', accessor: 'VPIP' },
      { Header: 'PFR', accessor: 'PFR' },
      { Header: '3-Bet', accessor: '3BET' },
      { Header: 'WTSD', accessor: 'WTSD' },
      { Header: 'WSD', accessor: 'WSD' },
      { Header: 'WWSF', accessor: 'WWSF' },
    ]
  }];


export const Styles = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    border-radius:2px;
    font-size:1em;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    thead tr:first-of-type {
      background-color:rgba(81, 81, 81, 1);
      color: white;
    }
    thead tr:nth-child(2) {
      background-color:rgb(151, 151, 151);
      color:white;
      border-bottom:5px solid black;
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid gray;
      border-right: 1px solid gray;

      :last-child {
        border-right: 0;
      }
    }
  }
`