import { createSelector } from 'reselect'

export const hudSitesSelector = (state => state?.hudSites?.data || [])
export const tournamentSitesSelector = (state => state?.tournamentSites?.data || [])


export const allSitesSelector = () => createSelector(
    hudSitesSelector,
    tournamentSitesSelector,
    (site => site)
)