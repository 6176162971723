import React, {useEffect, useState} from "react"
import Iframe from 'react-iframe'
import {Badge} from 'react-bootstrap'
import './bep.css'




function BrowserExtensionPage(props) {
    console.log("check props")
    console.log(props)
    const repoUrl = props.repoUrl
    const projectName = props.projectName
    const gitHubUrl = props.gitHubUrl
    const latestRelease = props.latestRelease
    const video = props?.video

    return (
        <div style={{marginLeft:'1em'}}>
        <div>
            <div style={{display:'flex', flexDirection: 'column'}}>
                <div style={{display:'flex', flexDirection: 'row'}}>
                    <h2>{projectName}</h2>
                </div>
                <div style={{display:'flex', flexDirection: 'column', marginBottom:'2em'}}>
                    <span><span className="badge">GitHub:</span><a style={{fontSize:'0.75em', marginLeft:'0.75em'}} href={gitHubUrl}>{gitHubUrl}</a></span>
                    <span><span className="badge">Release:</span><a style={{fontSize:'0.75em', marginLeft:'0.75em'}} href={latestRelease}>{latestRelease}</a></span>
                    {video && <span><span className="badge">Video:</span><a style={{fontSize:'0.75em', marginLeft:'0.75em'}} href={video}>{video}</a></span>}
                </div>
            </div>
            <Iframe 
                url={repoUrl}
                position="absolute"
                width="100%" 
                height="100%"/>
        </div>
        
        </div>
    )
}


export default BrowserExtensionPage