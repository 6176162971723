export const GET_TOURNAMENT_STAT_SITES = 'GET_TOURNAMENT_STAT_SITES'
export const GET_HUD_STAT_SITES = 'GET_HUD_STAT_SITES'

export const GET_ALL_HUD_PLAYERS = 'GET_ALL_HUD_PLAYERS'
export const GET_ALL_TOURNAMENT_PLAYERS = 'GET_ALL_TOURNAMENT_PLAYERS'

export const GET_SINGLE_PLAYER_HUD_STATS = 'GET_SINGLE_PLAYER_HUD_STATS'
export const REMOVE_SINGLE_PLAYER_HUD_STATS = 'REMOVE_SINGLE_PLAYER_HUD_STATS'
export const RESET_SINGLE_PLAYER_HUD_STATS = 'RESET_SINGLE_PLAYER_TOURNAMENT_STATS'


export const GET_SINGLE_PLAYER_TOURNAMENT_STATS = 'GET_SINGLE_PLAYER_TOURNAMENT_STATS'
export const REMOVE_SINGLE_PLAYER_TOURNAMENT_STATS = 'REMOVE_SINGLE_PLAYER_TOURNAMENT_STATS'
export const RESET_SINGLE_PLAYER_TOURNAMENT_STATS = 'RESET_SINGLE_PLAYER_TOURNAMENT_STATS'

export const GET_AGGREGATE_TOURNAMENT_STATS = 'GET_AGGREGATE_TOURNAMENT_STATS'

export const GET_REGISTERING_TOURNAMENTS = 'GET_REGISTERING_TOURNAMENTS'
export const GET_RUNNING_TOURNAMENTS = 'GET_RUNNING_TOURNAMENTS'

export const GET_RANDOM_TOURNAMENT = 'GET_RANDOM_TOURNAMENT'
export const GET_TOURNAMENT_DATA = 'GET_TOURNAMENT_DATA'



export const PENDING_STATUS = { isLoading: true, isError: false }
export const FULFILLED_STATUS = { isLoading: false, isError: false }
export const REJECTED_STATUS = { isLoading: false, isError: true }


