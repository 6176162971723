import react, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'

import stockLogo from '../assets/stockLogo.png'
import rounderLogo from '../assets/rounderLogo.png'
import loadingGif from '../assets/loading.gif'
import './overlaylist.css'
import { GlidingBlink } from 'react-loading-indicators'

import { getRunningTournaments, getRegisteringTournaments } from '../actions/actions'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function AvailableOverlays(props) {
    const { runningTournaments, registeringTournaments } = props
    const dispatch = useDispatch()
    const query = useQuery()

    const site = query.get('site')



    const renderLinks = (tournamentListInfo) => {
        const tournamentList = tournamentListInfo?.data
        const isLoading = tournamentListInfo?.isLoading

        if (isLoading) {
            return <div><GlidingBlink color="#0d0d0d" size="medium" textColor="#000000" /></div>
        }


        if (!tournamentList) {
            return null
        }


        if (tournamentList.length === 0) {
            return (<ul><li>No tournaments found</li></ul>)
        }

        const links = tournamentList.map((tinfo) => {

            const { tournamentName, site, uniqueId, tournamentState, startDate } = tinfo
            const sdo = new Date(startDate?.$date)
            const today = new Date()
            let formatted

            if (sdo.toDateString() === today.toDateString()) {
                let dayDescriptor
                if (sdo.getHours() > 18 || sdo.getHours() <= 3) {
                    dayDescriptor = "(Tonight"
                }
                else {
                    dayDescriptor = "(Today"
                }

                formatted = `${dayDescriptor} at ${sdo.toLocaleTimeString()}`
            }
            else {
                let monthDay = ''
                const diffInMs = Math.abs(sdo - today)
                const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
                if (diffInDays > 1.6) monthDay = `${sdo.getMonth() + 1}/${sdo.getDate()}`

                formatted = "(" + sdo.toLocaleString('default', { weekday: 'long' }) + ` ${monthDay} at ${sdo.toLocaleTimeString()} `
            }

            const prtcl = window.location.protocol
            const host = window.location.hostname
            const port = window.location.port



            const href1 = `${prtcl}//${host}:${port}/overlay/ticker/?uid=${uniqueId}&widgetType=ticker&tstate=${tournamentState}`
            const href2 = `${prtcl}//${host}:${port}/overlay/overlay/table/?uid=${uniqueId}&tstate=${tournamentState}`

            return (
                <div>
                    <style>
                        @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&amp;family=Teko&amp;display=swap')
                    </style>
                    <ul>
                        <li>
                            <div style={{ flex: 1 }}>
                                <div><span style={{ fontFamily: "Times New Roman serif", fontSize: '1.2em', fontWeight: 'bold' }}>{tournamentName}</span></div>
                                <div style={{ align: 'right', marginTop: '-0.2em', marginBottom: 15, marginRight: 0 }}><span style={{ fontSize: '1em', fontFamily: "Roboto condensed", fontWeight: 'bold' }}>
                                    {formatted} - [ <a href={href1}>Ticker</a> | <a href={href2}>Table</a> ] ) </span></div>
                            </div>
                        </li>
                    </ul>
                </div>
            )

        })

        return links
    }



    useEffect(() => {
        dispatch(getRegisteringTournaments(site))
        dispatch(getRunningTournaments(site))

    }, [])
    const siteUrl = 'https://' + site
    let siteLogo

    if (site === 'stockpokeronline.com') {
        siteLogo = stockLogo
    }
    else if (site == 'roundercasino.com') {
        siteLogo = rounderLogo
    }


    return (
        <div >
            <div className="tournament-list-wrapper">
                {site &&
                    <div style={{ marginLeft: '1em', flex: 1 }}><a href={siteUrl}><img className="site-selector" src={siteLogo} /></a></div>
                }


                <div>
                    <div style={{ marginLeft: '1em', color: 'black', fontSize: '0.8em' }}>
                        Below are links to graphical displays that can be imported to OBS Studio for livestreaming.
                        To use, simply find your tournament below and click on the corresponding "Ticker" or "Table
                         link.
                        Then, in OBS Studio, add a new browser source and copy the link that you clicked on 
                        and paste it into the URL input field in OBS Studio.
                        <br/><b>(Note: If using the ticker overlay, I highly recommended setting the height to 72.)</b>
                            <div style={{marginLeft:'auto',marginRight:'auto',textAlign:'center'}}>
                        <div style={{textAlign:'left', marginTop:'0.5em'}}>
                        <b>Features:</b>
                        <ul style={{marginLeft:'-1em', marginTop:'-0.1em'}}>
                        <li width="400px">Displays rank, chipstack, and total winnings for each player</li>
                        <li>Available in two varieties, a horizontal scrolling ticker or a table</li>
                        <li>Player rank information is updated live as tournament progresses</li>
                        </ul>
                        </div>
                        </div>
                        <Link to={'/obs-overlays'}><span style={{ marginLeft: '1em', fontWeight: 'bold', fontSize: '1.25em', marginLeft: 0, textDecoration: 'underline' }}>&lt; Pick a different site</span></Link>
                        <ul style={{ marginTop: 0 }}>

                        </ul>
                        <ul style={{ marginTop: -10 }}>
                            <span style={{ fontFamily: "Times New Roman", fontWeight: 'bold', fontSize: '1.5em', marginLeft: '-1em' }}>Running Tournaments:</span>
                            {renderLinks(runningTournaments)}

                        </ul><br />
                        <ul style={{ marginTop: -10 }}>
                            <span style={{ fontFamily: "Times New Roman", fontWeight: 'bold', fontSize: '1.5em', marginLeft: '-1em' }}>Registering Tournaments:</span>
                            {renderLinks(registeringTournaments)}

                        </ul>
                    </div>
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        runningTournaments: state.runningTournaments,
        registeringTournaments: state.registeringTournaments


    }
}

export default connect(mapStateToProps)(AvailableOverlays);