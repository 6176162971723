import {
    PENDING_STATUS,
    REJECTED_STATUS,
    FULFILLED_STATUS,

    GET_TOURNAMENT_STAT_SITES,
    GET_HUD_STAT_SITES,
    GET_ALL_HUD_PLAYERS,
    GET_ALL_TOURNAMENT_PLAYERS,

    GET_SINGLE_PLAYER_HUD_STATS,
    REMOVE_SINGLE_PLAYER_HUD_STATS,
    RESET_SINGLE_PLAYER_HUD_STATS,

    GET_SINGLE_PLAYER_TOURNAMENT_STATS,
    REMOVE_SINGLE_PLAYER_TOURNAMENT_STATS,
    RESET_SINGLE_PLAYER_TOURNAMENT_STATS,

    GET_AGGREGATE_TOURNAMENT_STATS,

    GET_RUNNING_TOURNAMENTS,
    GET_REGISTERING_TOURNAMENTS,

    GET_RANDOM_TOURNAMENT,
    GET_TOURNAMENT_DATA

} from '../actions/constants'


export const getTournamentStatSites = (state = [], action) => {
    switch (action.type) {
        case GET_TOURNAMENT_STAT_SITES + '_PENDING': {
            return { ...state, isLoading: true, isError: false, errorMessage: null }
        }

        case GET_TOURNAMENT_STAT_SITES + '_FULFILLED': {
            return {
                ...state,
                isLoading: false,
                isError: false,
                errorMessage: null,
                data: action.payload.data

            }
        }
        case GET_TOURNAMENT_STAT_SITES + "_REJECTED": {
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: "something went wrong"
            }
        }
        default:
            return state;
    }
}


export const getHudStatSites = (state = [], action) => {
    switch (action.type) {
        case GET_HUD_STAT_SITES + '_PENDING': {
            return { ...state, isLoading: true, isError: false, errorMessage: null }
        }

        case GET_HUD_STAT_SITES + '_FULFILLED': {
            return {
                ...state,
                isLoading: false,
                isError: false,
                errorMessage: null,
                data: action.payload.data
            }
        }


        case GET_HUD_STAT_SITES + "_REJECTED": {
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: "something went wrong"
            }
        }
        default:
            return state;

    }
}

export const getAllHudPlayers = (state = {}, action) => {
    switch (action.type) {
        case GET_ALL_HUD_PLAYERS + '_PENDING': {
            const existing = state?.[action.meta.site] || {}
            return {
                ...state,
                [action.meta.site]: {
                    ...existing,
                    ...PENDING_STATUS,
                }
            }

        }

        case GET_ALL_HUD_PLAYERS + '_FULFILLED': {
            const existing = state?.[action.meta.site] || {}
            return {
                ...state,
                [action.meta.site]: {
                    ...existing,
                    players: action.payload.data,
                    ...FULFILLED_STATUS
                }

            }
        }


        case GET_ALL_HUD_PLAYERS + "_REJECTED": {
            return {
                ...state,
                ...REJECTED_STATUS
            }
        }
        default:
            return state;

    }
}

export const setStat = (state = { site: null, statType: null }, action) => {
    console.log("set stat site action: ")
    console.log(action)

    switch (action.type) {
        case 'SET_STATISTIC_SITE': {
            return { ...state, 'site': action.site }
        }
        case 'SET_STATISTIC_TYPE': {
            return { ...state, 'statType': action.statType }
        }
        default: {
            return state
        }
    }
}


export const getAllTournamentPlayers = (state = {}, action) => {
    switch (action.type) {
        case GET_ALL_TOURNAMENT_PLAYERS + '_PENDING': {
            const existing = state?.[action.meta.site] || {}
            return {
                ...state,
                [action.meta.site]: {
                    ...existing,
                    ...PENDING_STATUS,
                }
            }
        }

        case GET_ALL_TOURNAMENT_PLAYERS + '_FULFILLED': {
            const existing = state?.[action.meta.site] || {}
            return {
                ...state,
                [action.meta.site]: {
                    ...existing,
                    players: action.payload.data,
                    ...FULFILLED_STATUS
                }

            }


        }


        case GET_ALL_TOURNAMENT_PLAYERS + "_REJECTED": {
            return {
                ...state,
                ...REJECTED_STATUS
            }
        }


        default:
            return state;

    }
}

export const getSinglePlayerHudStats = (state = {}, action) => {
    switch (action.type) {
        case GET_SINGLE_PLAYER_HUD_STATS + '_PENDING': {
            const existingData = state?.[action.meta.site]?.['data'] || []
            return {

                ...state, [action.meta.site]: {
                    'data': [...existingData],
                    ...state?.[action.meta.site],
                    ...PENDING_STATUS

                }
            }
        }




        case GET_SINGLE_PLAYER_HUD_STATS + '_FULFILLED': {

            const existingData = state?.[action.meta.site]?.['data'] || []
            return {

                ...state,
                [action.meta.site]: {
                    'data': [...existingData, ...action.payload.data.hud_data],
                    ...FULFILLED_STATUS
                }

            }

        }

        case GET_SINGLE_PLAYER_HUD_STATS + "_REJECTED": {

            return {
                ...state,
                ...REJECTED_STATUS
            }
        }

        case REMOVE_SINGLE_PLAYER_HUD_STATS + '_PENDING': {
            return {
                ...state, ...PENDING_STATUS
            }
        }


        case REMOVE_SINGLE_PLAYER_HUD_STATS + '_FULFILLED': {
            return {
                ...state, [action.meta.site]: {
                    data: state[action.meta.site]['data'].filter(hudData => hudData.player_name !== action.meta.player)
                }
            }
        }

        case RESET_SINGLE_PLAYER_HUD_STATS + '_PENDING': {
            return {
                ...state, ...PENDING_STATUS
            }
        }


        case RESET_SINGLE_PLAYER_HUD_STATS + '_FULFILLED': {
            return {}
        }


        default:
            return state;

    }
}

export const getSinglePlayerTournamentStats = (state = {}, action) => {
    switch (action.type) {
        case GET_SINGLE_PLAYER_TOURNAMENT_STATS + '_PENDING': {
            const existingData = state?.[action.meta.site]?.['data'] || []
            return {
                ...state, [action.meta.site]: {
                    'data': [...existingData],
                    ...state?.[action.meta.site],
                    ...PENDING_STATUS
                }
            }

        }
        case GET_SINGLE_PLAYER_TOURNAMENT_STATS + '_FULFILLED': {
            const existingData = state?.[action.meta.site]?.['data'] || []
            return {
                ...state,
                [action.meta.site]: {
                    'data': [...existingData, ...[action.payload.data]],
                    ...FULFILLED_STATUS
                }

            }

        }
        case GET_SINGLE_PLAYER_TOURNAMENT_STATS + "_REJECTED": {
            return {
                ...state,
                ...REJECTED_STATUS
            }


        }

        case REMOVE_SINGLE_PLAYER_TOURNAMENT_STATS + '_PENDING': {
            return {
                ...state, ...PENDING_STATUS
            }
        }


        case REMOVE_SINGLE_PLAYER_TOURNAMENT_STATS + '_FULFILLED': {

            return {
                ...state, [action.meta.site]: {
                    data: state[action.meta.site]['data'].filter(data => data.name !== action.meta.player)
                }
            }
        }

        case RESET_SINGLE_PLAYER_TOURNAMENT_STATS + '_PENDING': {
            return {
                ...state, ...PENDING_STATUS
            }
        }


        case RESET_SINGLE_PLAYER_TOURNAMENT_STATS + '_FULFILLED': {
            return {}
        }

        default:
            return state;

    }

}

export const getAggregateTournamentStats = (state = {}, action) => {
    switch (action.type) {
        case GET_AGGREGATE_TOURNAMENT_STATS + '_PENDING': {
            const existing = state?.[action.meta.site] || {}
            return {
                ...state,
                [action.meta.site]: {
                    ...existing,
                    ...PENDING_STATUS

                }
            }

        }
        case GET_AGGREGATE_TOURNAMENT_STATS + '_FULFILLED': {


            return {
                ...state,
                [action.meta.site]: {
                    ...action.payload.data,
                    ...FULFILLED_STATUS
                }


            }

        }
        case GET_AGGREGATE_TOURNAMENT_STATS + "_REJECTED": {
            return {
                ...state,
                ...REJECTED_STATUS
            }


        }

        default:
            return state;

    }

}

export const getRunningTournaments = (state = {}, action) => {
    switch(action.type) {
        case GET_RUNNING_TOURNAMENTS + '_PENDING': {
            return {...state, isLoading: true, isError: false, errorMessage: null}
        }

        case GET_RUNNING_TOURNAMENTS + '_FULFILLED': {
            return {
                ...state, 
                isLoading: false, 
                isError: false, 
                errorMessage: null, 
                data: action.payload.data}
            }
        

        case GET_RUNNING_TOURNAMENTS + "_REJECTED": {
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: "something went wrong"
            }
        }
        default: 
            return state;
    
    }
}




export const getRegisteringTournaments = (state = {}, action) => {
    switch(action.type) {
        case GET_REGISTERING_TOURNAMENTS + '_PENDING': {
            return {...state, isLoading: true, isError: false, errorMessage: null}
        }

        case GET_REGISTERING_TOURNAMENTS + '_FULFILLED': {
            return {
                ...state, 
                isLoading: false, 
                isError: false, 
                errorMessage: null, 
                data: action.payload.data}
            }
        

        case GET_REGISTERING_TOURNAMENTS + "_REJECTED": {
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: "something went wrong"
            }
        }
        default: 
            return state;
    
    }
}

export const getRandomTournament = (state = {}, action) => {
    switch(action.type) {
        case GET_RANDOM_TOURNAMENT + '_PENDING': {
            return {...state, isLoading: true, isError: false, errorMessage: null}
        }

        case GET_RANDOM_TOURNAMENT + '_FULFILLED': {
            return {
                ...state, 
                isLoading: false, 
                isError: false, 
                errorMessage: null, 
                data: action.payload.data}
            }
        

        case GET_RANDOM_TOURNAMENT + "_REJECTED": {
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: "something went wrong"
            }
        }
        default: 
            return state;
    
    }
}

export const getTournamentData = (state = {}, action) => {
    switch(action.type) {
        case GET_TOURNAMENT_DATA + '_PENDING': {
            return {...state, isLoading: true, isError: false, errorMessage: null}
        }

        case GET_TOURNAMENT_DATA + '_FULFILLED': {
            return {
                ...state, 
                isLoading: false, 
                isError: false, 
                errorMessage: null, 
                data: action.payload.data}
            }
        

        case GET_TOURNAMENT_DATA + "_REJECTED": {
            return {
                ...state,
                isLoading: false,
                isError: true,
                errorMessage: "something went wrong"
            }
        }
        default: 
            return state;
    
    }
}