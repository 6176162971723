import React from "react"
import { Link } from 'react-router-dom'
import { Nav } from "react-bootstrap"
import "./menu.css"

function Menu({ onClickLink }) {
    return (
        <div className="navigation">
            <Nav className="flex-column" onClick={onClickLink}>
                <Nav.Item>
                    <Link style={{ fontSize: '1.25em' }} to="/">Home</Link>
                </Nav.Item>
                <Nav.Item>
                    <Link style={{ fontSize: '1.25em' }} to="/obs-overlays">OBS / Livestream Overlays</Link>
                </Nav.Item>
                <Nav.Item>
                    <Link style={{ fontSize: '1.25em' }} to="/poker-stats">Statistics</Link>
                </Nav.Item>
                <Nav.Item>
                    <Link style={{ fontSize: '1.25em' }} to="/browser-extensions">Browser Extensions</Link>
                </Nav.Item>
            </Nav>
        </div>
    )
}

export default Menu