import Header from "./Header";


import {
    useLocation
} from "react-router-dom";

function Layout({children }) {
    

    //if(path.indexOf('obs-overlays/available'))

    return (
        <div>
            <Header/>
            {children}
        </div>
    )
}

export default Layout