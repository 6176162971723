import  React, {useEffect} from 'react'
import SidebarMenu from "./SidebarMenu"
import StandingsTicker from './StandingsTicker'
import './header.css'
import {connect, useDispatch} from 'react-redux'
import { getRandomTournament } from "../actions/actions"
function Header(props) {
    const randTournUid = props?.randomTournament?.data?.uid
    const tstate = props?.randomTournament?.data?.tstate

    useEffect(() => {
        props.getRandomTournament()
    },[])



    
    const iframeUrl = `https://cornblaster.com/overlays/roundercasino.com/?uid=${randTournUid}&widgetType=ticker&tstate=${tstate}`


    return (

        <div>
            <div style={{ flexDirection: 'column', display: 'flex' }}>
                <StandingsTicker uid={randTournUid} tstate={tstate} />
                
                
                
                <div style={{display:'flex',flexDirection: 'row','backgroundColor': '#1c1d1f',paddingLeft:20, alignItems:'center'}}>
                
                <div style={{"flexDirection": "row"}}><SidebarMenu /></div>
                <div id="header_title">Online Poker Tools</div>
                
                
                
                

                </div>
            </div>
        </div>


    )
}

const styles = {

    menuContainer: {
        width: '15%',
        paddingLeft: '20px'
    },

    headerTextWrapper: {
        width: '85%',
        display: 'flex',
        flexDirection: 'column',
        height: '25%',
        alignSelf: 'center'


    },

    wrappedText1: {
        color: 'white',
        fontSize: '2em',
        lineHeight: '50px',
        marginTop: '12px',
        fontWeight: 'bold',

    },
    subHeader: {
        color: 'white',
        fontSize: '24px',
        marginTop: '-30px',
        paddingLeft: '30px'

    },

    wrappedText2: {
        color: 'white',
        fontSize: '24px',
        marginTop: '-10px'
    }


}

const mapStateToProps = state => ({
    randomTournament: state.randomTournament,
  })
  
  const mapDispatchToProps = dispatch => ({
    getRandomTournament: () => {
      dispatch(getRandomTournament())
    },
  
  })
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Header)