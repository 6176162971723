import logo from './logo.svg';
import './App.css';

import React, { useEffect, useRef } from "react";
import ReactGA from "react-ga4";





import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";

import StatPicker from './components/StatPicker'
import Layout from './components/Layout';
import BrowserExtensionPage from './components/BrowserExtesnionPage';
import AvailableOverlays from './components/AvailableOverlays'
import { getRandomTournament } from './actions/actions';
import { connect } from 'react-redux'
import './App.css'
import stockLogo from './assets/stockLogo.png'
import rounderLogo from './assets/rounderLogo.png'




function App(props) {
  ReactGA.initialize("G-8P7GTRR6SQ");




  return (
    <Router>
      <Layout>
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route path="/obs-overlays" element={<ObsOverlays />} />
          <Route path="/obs-overlays/available" element={<AvailableOverlays />} />

          <Route path="/poker-stats" element={<Stats />} />

          <Route path="/browser-extensions" element={<BrowserExtensions />} />

          <Route path="/browser-extensions/everygame-poker-hud" element={
            <BrowserExtensionPage
              gitHubUrl="https://github.com/dchrostowski/everygame-poker-hud"
              repoUrl="https://git.streetscrape.com/dan/everygame-poker-hud"
              projectName="Heads-Up-Display for poker.everygame.eu"
              latestRelease="https://github.com/dchrostowski/everygame-poker-hud/releases/latest"
              video="https://www.youtube.com/watch?v=0H_7ni6yq-Y"

            />} />
          <Route path="/browser-extensions/stock-rounder-hand-tracker" element={
            <BrowserExtensionPage
              repoUrl="https://git.streetscrape.com/dan/hand-tracker-chrome-extension"
              latestRelease='https://github.com/dchrostowski/poker_hand_recorder_stock_rounder/releases/latest'
              gitHubUrl='https://github.com/dchrostowski/poker_hand_recorder_stock_rounder'
              projectName='Hand Recorder for stockpokeronline.com and roundercasino.com'
            />}
          />
          <Route path="/browser-extensions/stock-rounder-hud" element={
            <BrowserExtensionPage
              repoUrl="https://git.streetscrape.com/dan/stock-rounder-hud"
              latestRelease='https://github.com/dchrostowski/stock-rounder-hud/releases/latest'
              gitHubUrl='https://github.com/dchrostowski/stock-rounder-hud'
              projectName='Heads-Up-Display for stockpokeronline.com and roundercasino.com'
            />}
          />
        </Routes>
      </Layout>
    </Router>
  );

  function Home() {
    return (
      <>
        <div style={{
          display: 'flex', flexDirection: 'row', paddingLeft: '30px', position: 'absolute'
        }}>

          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '30px', marginBottom: -20, 'paddingRight': '30px' }}>
            <h2>About this site</h2>
            <p style={{ marginTop: -10 }}>I created this site to showcase some of my personal development projects involving online poker over the last few years.  
            Here you can find OBS plugins for livestreaming, archived hand and tournament data, and browser extensions which can be used for several online poker sites.</p>
            <div style={{ display: 'flex', flexDirection: 'row', }}>
              
              <div style={{ marginLeft: '0em', marginRight: '5em' }}>
                <h2>Compatible Sites</h2>
                <ul style={{ fontSize: '1em', marginTop: "-1em", marginLeft:"-1.8em" }}>
                  <li className='site-link'>
                    <a href="https://stockpokeronline.com/">Stock Poker Online</a>
                  </li>
                  <li className='site-link'>
                    <a href="https://roundercasino.com/">Rounder Casino</a>
                  </li>
                  <li className='site-link'>
                    <a href="https://poker.everygame.eu">Everygame Poker</a>
                  </li>
                  <li className='site-link'>
                    <a href="https://swcpoker.club">SwC Poker</a>
                  </li>
                </ul>
              </div>
              <div>
                <h2>Available Tools</h2>
                <ul style={{ fontSize: '1em', marginTop: "-1em", marginLeft:"-1.8em" }}>
                  <li className='site-link'>
                    <Link to="/poker-stats">Tournament & Hand Statistics</Link>
                  </li>
                  <li className='site-link'>
                    <Link to="/obs-overlays"> OBS / Twitch Stream Overlays</Link>
                  </li>
                  <li className='site-link'>
                    <Link to="/browser-extensions">Browser Extensions / HUDs</Link>
                  </li>
                </ul>
              </div>
              
            </div>
            <h2>Technical details</h2>
            <div style={{marginLeft:'1em'}}>
            <p style={{ marginTop: "-1em" }}>This system consists of four major components: <br/>
            <ol>
              <li>One <a href="https://www.mongodb.com/">MongoDB</a> instance, a "NoSQL" document database.</li>
              <li>Two <a href="https://nodejs.org">Node.JS</a> automated crawler/scraper applications (HandBot & TournamentBot) running inside a <a href="https://www.docker.com/">Docker</a> container.</li>
              <li>A RESTful web API written with <a href="https://flask.palletsprojects.com/en/2.2.x/">Flask</a>, a <a href="https://www.python.org/">Python</a> web framework.</li>
              <li>This web application, written with <a href="https://react.dev/">React</a>, a <a href="https://www.javascript.com/">JavaScript</a> web framework.</li>

            
            </ol>
            </p>
            
            <h2 style={{marginTop:'-0.5em'}}>Synopsis</h2>
            <div style={{marginLeft:'1em'}}>
            <h3 style={{marginTop:'-0.5em'}}>TournamentBot</h3>
            
            <p style={{ marginTop: "-1em" }}>
              The first Node.js scraper (TournamentBot) is responsible for collecting and saving statistics for completed and in-progress tournaments.  It does this 
              by establishing a websocket connection with each poker site that has been assigned in its configuration.
              It will start by sending a message every few minutes requesting information about each currently running and scheduled poker tournament listed on the site.
              For each running tournament, TournamentBot creates a temporary record consisting of each player/entrant, their rank, and chip stack as they
              play in the tournament.  This is then
              transported to the MongoDB instance where it is stored and utilized by the <a href="/obs-overlays">OBS overlays</a> that I have developed.
              When the tournament reaches its conclusion, TournamentBot will delete this temporary record and
              create a final record of the completed tournament which includes
              the tournament ID, start date, end date, buy in, entry fees, addons, rebuys, final ranking, and prize amounts for each player.
              I've published the source code for TournamentBot to <a href="https://github.com/dchrostowski/poker_tournament_scraper">this GitHub repository</a> along with 
              a docker-compose.yaml configuration which includes a containerized MongoDB service for convenience.
            </p>
            <h3 >HandBot</h3>
            <p style={{ marginTop: -10 }}>The second Node.js scraper (HandBot) is responsible for creating records of every hand at every table of each site assigned in its queue.  HandBot will start
              by issuing a command to the websocket, requesting to spectate a given table.  When a hand at the table starts, HandBot starts recording the relevant websocket
              messages and constructs a hand record which includes many data points such as each player's chip stack, actions (bet, call, fold, etc.),
              round (preflop, flop, turn, river, etc.), and community cards. Hand records conform to the Standardized Hand History specification (see <a href="https://hh-specs.handhistory.org/">https://hh-specs.handhistory.org/</a> for more information)
              At the conclusion of the hand, the hand record is finalized then stored in the MongoDB instance.  Hand records are later
              used as a data source for the <Link to={'/browser-extensions'}>heads-up-displays (HUDs) and browser extensions</Link> that I have developed for anyone to use free of charge.
            </p>
            </div>
            </div>



            <h2>Design Infrastructure Decisions and Justification</h2>
            <div style={{marginLeft:'1em'}}>
            <h3 style={{marginTop:'-0.5em'}}>Storage: MongoDB</h3>
            <p style={{marginTop:'-0.8em'}}>
            When considering my storage options, I ultimately decided that MongoDB was ideal due to its high efficiency and scalability coupled with 
            very little need for relational data structures offered up in popular database solutions such as MySQL and PostgreSQL.  While useful for a broad range of
            applications, relational databases come at a significant cost to system resources and throughput.  In my opinion, this makes them poor candidates 
            for use in this project, which has already generated several gigabytes of data spread throughout millions of individual records over the timespan of
            several months.
            In addition to this, the <a href="https://hh-specs.handhistory.org/">Standardized Hand History specification</a> (a data spec used in this project)
            strongly advises the use of JSON objects for the creation and manipulation of poker hand records.  JSON offers very high compatibility and simplicity 
            when interfacing with MongoDB's nearly identical storage format.
            </p>
            <h3>Data Specification: Standardized Hand History (<a href="https://hh-specs.handhistory.org/">hh-specs.handhistory.org</a>)</h3>
            <p style={{marginTop:'-0.8em'}}>
              The Standardized Hand History specification was chosen for these reasons:
              <ul style={{marginBottom:'-0.5em'}}>
                <li>
                  High thoroughness and attention to detail
                </li>
                <li>
                  Well documented & transparent
                </li>
                <li>
                  Commitment to interoperability
                </li>
                <li>
                  Strong adherence to open-source software philosophy
                </li>
                <li>
                  An established process for future revisions or additions to the specification
                </li>
              </ul>
            </p>

            <h3>Data Mining/Collection: Node.JS</h3>
            <p style={{marginTop:'-0.8em'}}>
            Node.JS was chosen due to JavaScript's high prevalence, versatility, my personal proficiency with 
            the language, extremely useful libraries such as <a href='https://mongoosejs.com'>mongoose</a> and <a href="https://github.com/websockets/ws">ws</a>, 
            and the convenience of utilizing JSON objects in the derivation, creation, and transport of data.  Node.JS was clearly the most powerful and useful tool 
            at my disposal while working on this project.
            </p>
            <h3>Data Transport: Flask</h3>
            <p style={{marginTop:'-0.8em'}}>
              The decision to use Flask was made primarily due to the existence of a pre-deployed Flask app being used for a previous project.  This removed the need to
              install and configure a new API system which often comes at a very high cost of time and effort.  This Flask app also
              had a functioning automated CI/CD process configured through my GitLab server further simplifying the process of extending & repurposing the API for this project.
              Secondary reasons were my personal proficiency with Python and familiarity with Flask.
            </p>
            <h3>User Interface: React</h3>
            <p style={{marginTop:'-0.8em'}}>
              The decision to use React was influenced by its widespread adoption & popularity coupled with my personal preference, proficiency, and years of experience
              with the framework.
              Utilization of several packages made available through the <a href="https://www.npmjs.com/">npm software registry</a> significantly sped 
              up development of derivative works such as my <Link to={'/obs-overlays'}>OBS overlays</Link> 
            </p>
            <h3>User Interface: Chrome Extensions / Browser Extensions</h3>
            <p style={{marginTop:'-0.8em'}}>
              With many online poker sites making game play available through web browsers, this initially seemed like an good venue to develop interactive tools
              such as heads-up-displays.  I encountered a problem when attempting to publish my Chrome extensions to the Chrome WebStore.  The tools I have developed
              are in conflict with Google Chrome's store policies which prohibit browser extensions that "facilitate gambling".  In my opinion, classifying poker as 
              gambling is questionable at best, as there is a significant skill factor involved in the game, making it very far removed from a simple game of chance 
              (i.e. my interpretation of gambling). I understand gambling can be a subjective term and that I am biased, and there's nothing I can do to get my 
              extensions published for the time being.
            </p>
            </div>


           
          </div>






        </div >
      </>

    );
  }

  function ObsOverlays() {
    const navigate = useNavigate()
    const stockRef = useRef(null)
    const rounderRef = useRef(null)
    return (
      <div style={{marginTop: '1em',display:'flex', flexDirection:'column', alignContent:'center', textAlign:'center'}}>
        
                        
        
        <div className="site-selector-container">
          <div className="which-site-wrapper">
            <span style={{marginLeft:'1em', marginRight: '1em'}} className="which-site">Which site are you streaming for?</span>
          </div>
            <div className="site-selector-wrapper">
              <div className="site-selector"
                ref={stockRef}
                onClick={() => navigate("/obs-overlays/available?site=stockpokeronline.com")}
                onMouseDown={() => stockRef.current.style['border-style'] = 'inset'}
                onMouseUp={() => stockRef.current.style['border-style'] = 'outset'}
                onMouseOut={() => stockRef.current.style['border-style'] = 'outset'}>
                <img className="site-selector" src={stockLogo} />
                <span style={{ padding: '4px' }}>stockpokeronline.com</span>
              </div>
              <div className="site-selector"
                ref={rounderRef}
                onClick={() => navigate("/obs-overlays/available?site=roundercasino.com")}
                onMouseDown={() => rounderRef.current.style['border-style'] = 'inset'}
                onMouseUp={() => rounderRef.current.style['border-style'] = 'outset'}
                onMouseOut={() => rounderRef.current.style['border-style'] = 'outset'}>
                <img className="site-selector" src={rounderLogo} />

                <span style={{ padding: '4px' }}>roundercasino.com</span>
              </div>
            </div>
          </div>
          
        </div>


    );
  }

  function Stats() {
    return (
      <div style={{ paddingLeft: '1em', marginTop: '2rem' }}>

        <StatPicker />
      </div>
    );
  }

  function BrowserExtensions() {
    return (
      <div style={{ marginLeft: '1em' }}>
        <h2>Browser Extensions</h2>
        <ul>
          <li className="site-link"><Link to="/browser-extensions/everygame-poker-hud">Heads-Up-Display for poker.everygame.eu</Link></li>
          <li className="site-link"><Link to="/browser-extensions/stock-rounder-hand-tracker">Hand Tracker for stockpokeronline.com and roundercasino.com</Link></li>
          <li className="site-link"><Link to="/browser-extensions/stock-rounder-hud">Heads-Up-Display for stockpokeronline.com and roundercasino.com</Link></li>
        </ul>
      </div>
    );
  }


}



// const mapStateToProps = state => ({
//   randomTournament: state.randomTournament,
// })

// const mapDispatchToProps = dispatch => ({
//   getRandomTournament: () => {
//     dispatch(getRandomTournament())
//   },

// })

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps,
// )(App)

export default App